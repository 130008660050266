import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { PrimaryButton, CancelButton } from '../ui/buttons';
import { Field, FormDropDown } from '../ui/fields';
import Form from './styles/Form';
import { useIsSubmitting } from '../../hooks/useIsSubmitting';

const ActionWrapper = styled.span`
  display: flex;
  justify-content: center;
`;

const StyledGroupCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: auto;
  width: auto;
  padding: 36px 300px;
  border-radius: 8px;
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
`;

const StyledInfoBlock = styled.div`
  & > h6 {
    margin: 3px 0px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledSpan = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.green};
`;

const validateDropdowns = (list, setError, clearErrors) => {
  clearErrors();
  list.forEach((e) => {
    if (!e.value) {
      setError(`${e.name}`, {
        type: 'text',
        message: `Choose ${e.name}`,
      });
    }
  });
};

const EmployeeForm = ({
  initialValues,
  locations,
  departments,
  onUpdate,
  onSubmitEnd,
  isEditable,
  changeEditState,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState(0);

  const {
    register,
    handleSubmit: formHandleSubmit,
    errors,
    setError,
    clearErrors,
  } = useForm();
  const { isSubmitting, startSubmit, endSubmit } = useIsSubmitting({
    onSubmitEnd,
  });

  const {
    totalConnections,
    departmentId,
    groupsJoined,
    id,
    locationId,
    messagesSent,
    firstName,
    lastName,
    earthTokensEarned,
  } = initialValues;

  useEffect(() => {
    setSelectedDepartment(departmentId || 0);
    setSelectedLocation(locationId || 0);
  }, [locationId, departmentId]);

  const updateEmployee = (formData) => onUpdate(formData);

  const onSubmit = async (formData) => {
    startSubmit();
    const action = updateEmployee;
    await action({
      departmentId: selectedDepartment,
      locationId: selectedLocation,
      ...formData,
    });
    endSubmit();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    validateDropdowns(
      [
        { value: selectedLocation, name: 'Location' },
        { value: selectedDepartment, name: 'Department' },
      ],
      setError,
      clearErrors,
    );

    formHandleSubmit(onSubmit)(e);
  };

  return (
    <StyledGroupCard key={id}>
      <StyledInfoWrapper>
        <StyledInfoBlock>
          <h6>
            <StyledSpan>{earthTokensEarned}</StyledSpan> Earth Tokens
          </h6>
        </StyledInfoBlock>
        <StyledInfoBlock>
          <h6>
            <StyledSpan>{totalConnections}</StyledSpan> Connections
          </h6>
        </StyledInfoBlock>
        <StyledInfoBlock>
          <h6>
            <StyledSpan>{groupsJoined}</StyledSpan> Groups
          </h6>
        </StyledInfoBlock>
        <StyledInfoBlock>
          <h6>
            <StyledSpan>{messagesSent}</StyledSpan> Messages
          </h6>
        </StyledInfoBlock>
      </StyledInfoWrapper>
      <Form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit}>
        <Field
          disabled={!isEditable}
          label="Employee First Name"
          type="text"
          formErrors={errors}
          defaultValue={firstName}
          inputRef={register({
            required: {
              value: true,
              message: 'Please enter employee first name',
            },
          })}
          name="firstName"
        />
        <br />
        <Field
          disabled={!isEditable}
          label="Employee Last Name"
          type="text"
          formErrors={errors}
          defaultValue={lastName}
          inputRef={register({
            required: {
              value: true,
              message: 'Please enter employee last name',
            },
          })}
          name="lastName"
        />
        <br />
        <FormDropDown
          isEditable={isEditable}
          label="Location"
          list={locations}
          name="location"
          style={{ width: '384px' }}
          ref={register({
            required: 'Please enter location',
          })}
          value={selectedLocation}
          setValue={setSelectedLocation}
          formErrors={errors}
        />
        <br />
        <FormDropDown
          isEditable={isEditable}
          label="Department"
          list={departments}
          name="department"
          style={{ width: '384px' }}
          ref={register({
            required: 'Please enter department',
          })}
          value={selectedDepartment}
          setValue={setSelectedDepartment}
          formErrors={errors}
        />
        <br />
        {isEditable === true ? (
          <>
            <ActionWrapper>
              <CancelButton
                onClick={() => changeEditState(false)}
                style={{ marginRight: '35px' }}
                type="button">
                Cancel
              </CancelButton>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                Save
              </PrimaryButton>
            </ActionWrapper>
          </>
        ) : (
          ''
        )}
      </Form>
    </StyledGroupCard>
  );
};

export default EmployeeForm;
