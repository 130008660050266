import React, { useState, useEffect } from 'react';
import { Auth } from '../../services';
import { useAuth } from '../auth';

const CompanyContext = React.createContext();

export function useCompany() {
  const context = React.useContext(CompanyContext);
  if (context === undefined || context === null) {
    throw new Error('useCompany must be used within a CompanyProvider');
  }

  return context;
}

// MOCK COMPANY CALL

export const CompanyProvider = ({ children }) => {
  const [company, setCompany] = useState({});
  const [companies, setCompanies] = useState([]);
  const { user } = useAuth();

  const getCompanyData = async (isSuperAdmin) => {
    try {
      const allCompaniesData = Auth.getAllCompanies();
      const selectedCompanyData = Auth.getSelectedCompany();

      if (isSuperAdmin && allCompaniesData && selectedCompanyData) {
        setCompanies(allCompaniesData);
      } else if (selectedCompanyData) {
        setCompany(selectedCompanyData);
      }
      return selectedCompanyData;
    } catch (error) {
      console.info(error);
    }
    return null;
  };

  const changeSelectedCompany = (newCompany) => {
    setCompany(newCompany);
    Auth.setSelectedCompany(newCompany);
  };

  useEffect(() => {
    getCompanyData(user.isSuperAdmin);
  }, [user.isSuperAdmin]);

  const context = {
    company,
    changeSelectedCompany,
    companies,
    setCompanies,
  };

  return (
    <CompanyContext.Provider value={context}>
      {children}
    </CompanyContext.Provider>
  );
};
