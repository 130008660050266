import styled, { css } from 'styled-components/macro';

const activeStyle = css`
  color: ${({ theme }) => theme.colors.white};
`;

const hoverStyle = css`
  background-color: ${({ theme }) => theme.colors.electricLime};
`;

const PopupButton = styled.button`
  display: flex;
  justify-content: left;
  border: none;
  background-color: white;
  margin: 8px 0;
  text-decoration: none;
  text-align: left;
  align-items: center;
  height: 37px;
  width: 231px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;

  :hover {
    ${hoverStyle}
  }
  cursor: pointer;
  transition: 0.3s ease;
  ${(p) => p.$isActive && activeStyle}
  ${(span) => span.$isActive && activeStyle}
`;

export default PopupButton;
