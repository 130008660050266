import React from 'react';

const SuccessIcon = ({ style = {} }) => {
  return (
    <svg
      style={style}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.6093 7.81206C50.2857 6.88288 50.0842 5.57492 49.113 4.96027C44.0675 1.76696 38.2156 0.0414083 32.217 0.000735539C25.5229 -0.0446521 18.9832 2.0104 13.5184 5.87662C8.05358 9.74284 3.93883 15.2255 1.75338 21.5529C-0.432083 27.8803 -0.578193 34.7337 1.33561 41.1485C3.24941 47.5633 7.12676 53.2164 12.4218 57.312C17.7169 61.4076 24.1631 63.7395 30.853 63.9794C37.5429 64.2194 44.1395 62.3553 49.7144 58.6496C54.7102 55.3288 58.66 50.679 61.1311 45.2431C61.6067 44.1968 61.0587 42.9922 59.9837 42.5856C58.9087 42.1789 57.7147 42.7249 57.2289 43.7665C55.0728 48.3897 51.6809 52.3447 47.4104 55.1834C42.5606 58.4071 36.8219 60.0287 31.0022 59.82C25.1824 59.6113 19.5747 57.5827 14.9683 54.0197C10.3619 50.4568 6.98889 45.539 5.324 39.9586C3.65912 34.3782 3.78623 28.4161 5.68743 22.9117C7.58863 17.4073 11.1682 12.6377 15.9222 9.27438C20.6763 5.91103 26.3654 4.12326 32.1887 4.16275C37.3165 4.19752 42.321 5.64754 46.6578 8.33359C47.6349 8.93877 48.9328 8.74124 49.6093 7.81206Z"
        fill="#419385"
      />
      <rect
        x="63.3438"
        y="13.0664"
        width="46.5873"
        height="4"
        rx="2"
        transform="rotate(135 63.3438 13.0664)"
        fill="#419385"
      />
      <rect
        x="30.2363"
        y="45.7839"
        width="20.4714"
        height="4"
        rx="2"
        transform="rotate(-135 30.2363 45.7839)"
        fill="#419385"
      />
    </svg>
  );
};
export default SuccessIcon;
