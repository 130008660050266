import { css } from 'styled-components/macro';

const PrimaryButtonBase = css`
  border-radius: 50px;
  padding: 12px 24px;
  margin: 8px 0px;
  font-size: 16px;
  height: 43px;
  width: 180px;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  outline: none;
  :disabled {
    background-color: ${({ theme }) => theme.colors.darkGrey};
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.darkGreenDarker};
  }
  transition: 0.1s ease;
`;

export default PrimaryButtonBase;
