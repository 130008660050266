import { serverErrors } from '../../constants/errors';
import { Auth, Fetch, Log } from '../../services';
import { actionTypes } from './actionTypes';

export const requestToken = async (token, dispatch) => {
  const JWTToken = await Fetch.postJSON('/auth/token', { token });
  Auth.setToken(JWTToken.token);
  const user = await Fetch.getJSON('/self');
  Auth.setUser(user);
  if (!user.isSuperAdmin) {
    const response = await Fetch.getJSON('/self/company');
    const [usersCompany] = response.items;
    Auth.setSelectedCompany(usersCompany);
  }

  dispatch({
    type: actionTypes.LOGIN_SUCCESS,
    payload: { user, token: JWTToken.token },
  });
};

export async function loginUser(dispatch, queryTokenKey, email, history) {
  try {
    if (queryTokenKey) {
      await requestToken(queryTokenKey, dispatch);
    } else {
      await Fetch.postJSON('/signin/admin', { email });
      dispatch({ type: actionTypes.REQUEST_LOGIN_LINK_SUCCESS });
    }
  } catch (error) {
    const { code } = error;
    if (code === serverErrors.invite.not_found) {
      dispatch({ type: actionTypes.REQUEST_LOGIN_LINK_ERROR, error });
    } else {
      dispatch({ type: actionTypes.LOGIN_ERROR, error });
    }
    Log.out(error);
    if (queryTokenKey) {
      history.replace({
        search: '',
      });
    }
  }
}

export async function logout(dispatch) {
  dispatch({ type: actionTypes.LOGOUT });
  Auth.deleteToken();
  Auth.deleteUser();
  Auth.deleteAllCompanies();
  Auth.deleteSelectedCompany();
}

export async function resetStatusState(dispatch) {
  dispatch({ type: actionTypes.RESET_STATUS_STATE });
}
