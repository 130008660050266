import styled from 'styled-components/macro';
import PrimaryButtonBase from './ButtonBase';

const ExportButton = styled.button`
  ${PrimaryButtonBase}
  display: flex;
  background-color: transparent;
  justify-content: space-evenly;
  margin-right: 50px;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.lightGreen};
  padding: 0;

  :hover {
    border: 2px solid ${({ theme }) => theme.colors.electricLime};
    color: ${({ theme }) => theme.colors.electricLime};
  }
  transition: 0.1s ease;
`;

export default ExportButton;
