import React, { useState, useEffect, useMemo } from 'react';
import { Fetch } from '../../services';
import { useAuth } from '../auth';
import { FormDropDown } from '../ui/fields';
import { errorNotification } from '../ui/notifications/ErrorNotification';

const CompaniesDropDown = ({
  selectedCompany,
  setSelectedCompany,
  errors,
  companyId,
}) => {
  const [companies, setCompanies] = useState([]);
  const { user } = useAuth();

  const companyOptions = useMemo(() => {
    return companies.map((c) => ({ value: c.id, name: c.name }));
  }, [companies]);

  useEffect(() => {
    if (user.isSuperAdmin)
      Fetch.getJSON(`/company?limit=all`)
        .catch(() => errorNotification('Error while getting companies'))
        .then((data) => {
          setCompanies(data.items);
        });
  }, [user.isSuperAdmin]);

  const changeSelectedCompany = (value) => {
    setSelectedCompany({
      value,
      name: companyOptions.find(
        (companyOption) => companyOption.value === value,
      )?.name,
    });
  };

  return (
    <>
      {user.isSuperAdmin && (
        <FormDropDown
          isEditable
          label="Company"
          name="Company"
          list={companyOptions}
          value={companyId || selectedCompany.value}
          setValue={changeSelectedCompany}
          formErrors={errors}
        />
      )}
    </>
  );
};

export default CompaniesDropDown;
