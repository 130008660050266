import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _get from 'lodash/get';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Close, MoreVert } from '@material-ui/icons';
import styled from 'styled-components/macro';
import PopUp from '../PopUp';
import { Delete } from '../../../assets/icons/index';

import { IconButton, LinkButton, PopupButton } from '../buttons';

const ActionWrapper = styled.span`
  display: flex;
`;
const DeletePopupButton = styled(PopupButton)`
  color: ${({ theme }) => theme.colors.red};
`;

const pluralToSingular = {
  companies: 'company',
  groups: 'group',
  employees: 'employee',
  activities: 'activity',
  departments: 'department',
  locations: 'location',
};

const getColumnValue = (column, rowData) => {
  const { field, valueFormatter } = column;
  const value = _get(rowData, field);
  return valueFormatter ? valueFormatter(value, rowData.id, rowData) : value;
};

const getActionsDisablers = (columns) => {
  const actionsColumn = columns.find(({ field }) => field === 'actions');
  const deleteDisabler = _get(actionsColumn, 'disableDelete');
  const editDisabler = _get(actionsColumn, 'disableEdit');
  return { deleteDisabler, editDisabler };
};

const Row = (props) => {
  const {
    company,
    rowData,
    columns,
    onEdit,
    onDelete,
    isOpen,
    rowType,
    hasDetails,
    onResend,
    style,
    font,
    number,
    prevPageUrlParams,
    onTokenRedemptionAction,
  } = props;

  const location = useLocation();
  const useRowStyles = makeStyles({
    cell: {
      height: '55px',
      padding: '0 25px',
      lineHeight: '1rem',
      fontSize: font,
    },
  });

  const classes = useRowStyles();
  const hasActions = onEdit || onDelete || onTokenRedemptionAction;

  const isTokenRedemptionPending = useMemo(() => {
    return (
      onTokenRedemptionAction &&
      !(
        getColumnValue({ field: 'approvedAt' }, rowData) ||
        getColumnValue({ field: 'rejectedAt' }, rowData)
      )
    );
    // eslint-disable-next-line
  }, [onTokenRedemptionAction]);

  const { deleteDisabler } = getActionsDisablers(columns);

  const disableDeleteForRow = deleteDisabler && deleteDisabler(rowData);

  const deleteRef = useRef();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const id = isPopperOpen ? 'simple-popper' : undefined;

  const handleMoreActions = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setIsPopperOpen(!isPopperOpen);
  };

  const onPressDelete = () => {
    setAnchorEl(null);
    setIsPopperOpen(false);
    onDelete(rowData);
  };

  const handleCancel = () => {
    setAnchorEl(null);
    setIsPopperOpen(false);
  };

  const columnId = useMemo(() => {
    return getColumnValue({ field: 'id' }, rowData);
    // eslint-disable-next-line
  }, [rowData]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isPopperOpen &&
        deleteRef.current &&
        !deleteRef.current.contains(e.target)
      ) {
        handleCancel();
      }
    };

    document.addEventListener('mouseup', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mouseup', checkIfClickedOutside);
    };
  }, [isPopperOpen]);

  const detailsUrl = `/app/${rowType}/${company}/${rowData.id}`;
  const editUrl = `/app/${rowType}/edit/${company}/${rowData.id}`;
  const PopperConfirmation = () => {
    return (
      <div
        ref={deleteRef}
        style={{
          transform: 'translateX(-40%) translateY(0%)',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0px 16px 36px rgba(0, 0, 0, 0.08)',
        }}>
        <IconButton onClick={handleCancel} icon={<Close />} end />
        {rowType === 'companies' && (
          <>
            {/*
             <PopupButton
              onClick={() => onAddTokenRedemption(rowData)}
              type="submit">
              Add token redemption
            </PopupButton>
           <PopupButton onClick={() => onResetEarthTokens()} type="submit">
              Reset Earth tokens
            </PopupButton>
            <PopupButton onClick={() => onResetConnections()} type="submit">
              Reset connections
        </PopupButton> */}
            <Link
              style={{ textDecoration: 'none' }}
              to={`/app/earthtokenshistory/${getColumnValue(
                { field: 'id' },
                rowData,
              )}`}>
              <PopupButton>Earth tokens history</PopupButton>
            </Link>
          </>
        )}

        {rowType === 'departments' ||
        rowType === 'companies' ||
        rowType === 'locations' ? (
          <PopupButton onClick={() => onEdit(rowData)} type="submit">
            Edit {pluralToSingular[rowType]}
          </PopupButton>
        ) : (
          onEdit && (
            <Link
              style={{ textDecoration: 'none' }}
              to={{
                pathname: editUrl,
                state: { prevPath: location.pathname, prevPageUrlParams },
              }}>
              <PopupButton onClick={() => {}} type="submit">
                Edit {pluralToSingular[rowType]}
              </PopupButton>
            </Link>
          )
        )}
        {isTokenRedemptionPending && onTokenRedemptionAction?.onApprove && (
          <PopupButton
            onClick={() => {
              onTokenRedemptionAction.onApprove(columnId);
              handleCancel();
            }}
            type="submit">
            Approve token redemption
          </PopupButton>
        )}
        {isTokenRedemptionPending && onTokenRedemptionAction?.onReject && (
          <DeletePopupButton
            onClick={() => {
              onTokenRedemptionAction.onReject(columnId);
              handleCancel();
            }}
            type="submit">
            Reject token redemption
          </DeletePopupButton>
        )}
        {onDelete && (
          <DeletePopupButton onClick={() => onPressDelete()} type="submit">
            Delete {pluralToSingular[rowType]}
          </DeletePopupButton>
        )}
      </div>
    );
  };
  const rowStyle = !style ? { width: '100%', height: '55px' } : style;
  return (
    <>
      <TableRow style={rowStyle} className={classes.tableRow}>
        {number && <TableCell className={classes.cell}>{number}</TableCell>}
        {columns.map((column, index) => {
          const { align } = column;

          return (
            <TableCell
              className={classes.cell}
              key={`${column.field}${index}`}
              align={align || 'left'}
              style={{ fontFamily: 'inherit' }}>
              {getColumnValue(column, rowData)}
            </TableCell>
          );
        })}
        {hasActions && (
          <TableCell
            className={classes.cell}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}>
            {hasDetails && (
              <Link
                to={{
                  pathname: detailsUrl,
                  state: { prevPath: location.pathname, prevPageUrlParams },
                }}>
                View Details
              </Link>
            )}
            {onResend && (
              <LinkButton onClick={() => onResend(rowData.email)}>
                Resend Invite
              </LinkButton>
            )}
            <ActionWrapper>
              {(onDelete || onTokenRedemptionAction) &&
                rowType !== 'invite' && (
                  <IconButton
                    onClick={handleMoreActions}
                    icon={<MoreVert />}
                    disabled={isOpen || disableDeleteForRow}
                  />
                )}
              {onDelete && rowType === 'invite' && (
                <IconButton
                  onClick={onPressDelete}
                  icon={<Delete color="black" />}
                  disabled={isOpen || disableDeleteForRow}
                />
              )}
              <PopUp
                anchorEl={anchorEl}
                id={id}
                handlePopperClose={() => {}}
                isPopperOpen={isPopperOpen}
                displayComponent={<PopperConfirmation />}
              />
            </ActionWrapper>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default Row;
