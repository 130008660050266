import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { addYears } from 'date-fns';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { FormControlLabel, Checkbox, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Field,
  TextareaField,
  EditableImage,
  FormDropDown,
} from '../ui/fields';
import Label from '../ui/fields/common/Label';
import Form from './styles/Form';
import { useIsSubmitting } from '../../hooks/useIsSubmitting';
import { CancelButton, PrimaryButton } from '../ui/buttons';
import ActivityCheckGroup from './ActivityCheckGroup';
import {
  repeatsText as getRepeatsText,
  repeatPeriodValues,
} from '../../utils/helpers';
import { Fetch } from '../../services';
import { errorNotification } from '../ui/notifications/ErrorNotification';
import CompaniesDropDown from '../common/CompaniesDropDown';
import { useAuth } from '../auth';

const useStyles = makeStyles(() => ({
  formControlLabel: {
    width: 'auto',
    whiteSpace: 'nowrap',
  },
  formControl: {
    width: '48%',
    height: '100%',
  },
  field: {
    marginLeft: 'auto',
    width: '70px',
  },
  radio: {
    color: 'black',
  },
  select: {
    height: '52px',
    marginTop: '8px',
    width: '100%',
    borderRadius: '6px',
  },
  MenuItem: {
    width: '100%',
  },
}));

const Column = styled.div`
  width: 48%;
`;

const ActionWrapper = styled.span`
  display: flex;
  justify-content: center;
  margin: 15px 0;
`;

const StyledGroupCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  background-color: white;
  height: auto;
  width: 800px;
  padding: 36px 200px;
  border-radius: 8px;
`;

const StyledGrouping = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;
const ActivityRepetitionBlock = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

const labelStyle = { fontWeight: '600', margin: '5px 0px' };

const today = new Date().toISOString().split('T')[0];
const activityMaxDate = addYears(new Date(), 20).toISOString().split('T')[0];

const validateDropdowns = (list, setError, clearErrors) => {
  clearErrors();
  list.forEach((e) => {
    if (!e.value && e.value !== 0) {
      setError(`${e.name}`, {
        type: 'text',
        message: `Choose ${e.name}`,
      });
    }
  });
};

const ActivityDetailsForm = ({
  initialValues = {},
  onUpdateOrCreate,
  onSubmitEnd,
  isEditable,
  changeEditState,
  submitButtonRef,
  groups,
  selectedCompany,
  setSelectedCompany,
  isCreate,
}) => {
  const history = useHistory();
  const {
    register,
    handleSubmit: formHandleSubmit,
    errors,
    setError,
    clearErrors,
  } = useForm();
  const params = useParams();
  const { isSubmitting, startSubmit, endSubmit } = useIsSubmitting({
    onSubmitEnd,
  });
  const classes = useStyles();
  const [selectedDaysArray, setSelectedDaysArray] = useState([]);
  const [selectedRadioValue, setSelectedRadioValue] = useState('Ocurrence');
  const [selectedGroup, setSelectedGroup] = useState();
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [categories, setCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState();
  const [group, setGroup] = useState('None');
  const [isAllDay, setIsAllDay] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [activityRecurringData, setActivityRecurringData] = useState({});
  const { user } = useAuth();
  const {
    name,
    category,
    location,
    groupId,
    isRecurring: repeats,
    endTime: endDateTime = 0,
    time: startDateTime = 0,
    description,
    imageUrl,
    isAllDay: activityIsAllDay,
    recurringData: initialRecurringData,
  } = initialValues;

  const setRepeatEveryUnit = (value) => {
    setActivityRecurringData((prev) => {
      return { ...prev, repeatEveryUnit: value };
    });
  };

  const getCategories = () => {
    Fetch.getJSON('/categories')
      .catch(() => errorNotification('Error while getting categories'))
      .then((data) => {
        setCategories(data.items);
      });
  };

  const getGroup = () => {
    Fetch.getJSON(`/company/${params.companyID}/group/${groupId}`)
      .catch(() => errorNotification('Error while getting group details'))
      .then((data) => {
        setGroup(data.name);
        setSelectedGroup(data.id);
      });
  };

  const findRepeatPeriodIndex = (tag) => {
    return repeatPeriodValues.findIndex((e) => e.tag === tag);
  };

  useEffect(() => {
    setIsAllDay(!!activityIsAllDay);
    if (!initialRecurringData) return;
    setIsRecurring(true);
    setActivityRecurringData({
      ...initialRecurringData,
      repeatEveryUnit: findRepeatPeriodIndex(
        initialRecurringData?.repeatEveryUnit,
      ),
    });

    if (initialRecurringData.recurringEndsOn) setSelectedRadioValue('Date');
  }, [activityIsAllDay, initialRecurringData]);

  const isActivityRepeatedWeekly = useMemo(() => {
    return activityRecurringData.repeatEveryUnit === 1;
  }, [activityRecurringData.repeatEveryUnit]);

  const updateActivity = (formData, recurringData) => {
    onUpdateOrCreate(formData, groupId || selectedGroup, {
      category: categories[categoryValue]?.name,
      isAllDay,
      isRecurring,
      recurringData,
    });
  };

  const getTimeValues = () => {
    const activityStartTime = new Date(startDateTime).toTimeString();
    const activityEndTime = new Date(endDateTime).toTimeString();
    const activityStartDate = new Date(startDateTime)
      .toISOString()
      .substring(0, 10);
    setStartDate(activityStartDate);
    setStartTime(activityStartTime.substring(0, 5));
    setEndTime(activityEndTime.substring(0, 5));
  };

  const findCategory = () => {
    const index = categories.findIndex((item) => item.name === category?.name);
    setCategoryValue(index);
  };

  const createRecurringInfo = (formData) => {
    const endsData = {};
    if (formData.target.repeatsAmt?.value) {
      endsData.isRecurringEnds = 'After';
      endsData.recurringEndsAfter = formData.target.repeatsAmt.value;
    } else if (formData.target.SpecificDate?.value) {
      endsData.isRecurringEnds = 'On';
      endsData.recurringEndsOn = formData.target.SpecificDate.value;
    }
    if (isActivityRepeatedWeekly) {
      if (selectedDaysArray.length) endsData.repeatOn = [...selectedDaysArray];
      else {
        setError(`weekDays`, {
          type: 'text',
          message: `Pick recurring days`,
        });
        return {};
      }
    }

    const recurringData = {
      repeatEveryUnit:
        repeatPeriodValues[activityRecurringData.repeatEveryUnit].tag,
      repeatEveryValue: formData.target.numOfRepetitions.value,
      ...endsData,
    };
    return recurringData;
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groups) return;
    if (groupId) getGroup();
    getTimeValues();
    findCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleRadioButtonChange = (e) => {
    setSelectedRadioValue(e.target.value);
  };

  const onSubmit = async (formData, e) => {
    let recurringData = {};
    if (isRecurring && !initialRecurringData) {
      recurringData = createRecurringInfo(e);
    }
    const appendedFormData = { ...formData, weekDays: selectedDaysArray };
    startSubmit();
    await updateActivity(appendedFormData, recurringData);
    endSubmit();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    validateDropdowns(
      [
        { value: selectedGroup, name: 'Group' },
        { value: categoryValue, name: 'Category' },
        ...(user.isSuperAdmin
          ? [
              {
                value: params.companyID || selectedCompany.value,
                name: 'Company',
              },
            ]
          : []),
      ],
      setError,
      clearErrors,
    );
    formHandleSubmit(onSubmit)(e);
  };

  const repeatsText = repeats
    ? getRepeatsText(
        initialRecurringData.repeatOn,
        initialRecurringData.repeatEveryUnit,
        initialRecurringData.recurringEndsOn ||
          initialRecurringData.recurringEndsAfter,
      )
    : '';

  const CustomRadioButton = (value) => (
    <Radio
      color="primary"
      checked={selectedRadioValue === value}
      onChange={handleRadioButtonChange}
      value={value}
      name="radio-buttons"
    />
  );
  return (
    <StyledGroupCard>
      <Form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit}>
        <StyledGrouping>
          {isCreate && (
            <CompaniesDropDown
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              errors={errors}
            />
          )}
          {groups ? (
            <FormDropDown
              isEditable={isEditable}
              label="Group"
              list={groups}
              ref={register({
                required: { value: true, message: 'Please enter group name' },
              })}
              name="Group"
              value={selectedGroup}
              setValue={setSelectedGroup}
              formErrors={errors}
            />
          ) : (
            <Field
              disabled
              label="Group"
              type="text"
              formErrors={errors}
              value={group}
              inputRef={register({
                required: { value: true, message: 'Please enter group name' },
              })}
              name="group"
            />
          )}
        </StyledGrouping>
        <StyledGrouping>
          <Field
            disabled={!isEditable}
            label="Activity Name"
            type="text"
            formErrors={errors}
            defaultValue={name}
            inputRef={register({
              required: { value: true, message: 'Please enter activity name' },
            })}
            name="name"
          />
          <FormDropDown
            isEditable={isEditable}
            label="Activity category"
            list={categories}
            name="Category"
            ref={register({
              required: 'Please enter category',
            })}
            value={categoryValue}
            setValue={setCategoryValue}
            formErrors={errors}
          />
        </StyledGrouping>
        <StyledGrouping>
          <Field
            disabled={!isEditable}
            label="Location"
            type="text"
            defaultValue={location}
            inputRef={register({
              required: { value: true, message: 'Please enter location' },
            })}
            name="location"
          />
          <Field
            disabled={!isEditable}
            label="Activity Date"
            type="date"
            min={today}
            max={activityMaxDate}
            defaultValue={startDate}
            inputRef={register({
              required: { value: true, message: 'Please enter date' },
            })}
            name="date"
          />
        </StyledGrouping>
        <Label>Activity Time</Label>
        <StyledGrouping>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '30px',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              width: '48%',
            }}>
            <Field
              disabled={!isEditable}
              type="time"
              size="100%"
              formErrors={errors}
              defaultValue={startTime}
              inputRef={register({
                required: { value: true, message: 'Please enter start time' },
              })}
              name="startTime"
            />
            <Field
              disabled={!isEditable}
              label=" "
              type="time"
              size="100%"
              formErrors={errors}
              defaultValue={endTime}
              inputRef={register({
                required: { value: true, message: 'Please enter end time' },
              })}
              name="endTime"
            />
          </div>
          {!isEditable && initialRecurringData && (
            <ActivityRepetitionBlock>
              <h5 style={{ textAlign: 'center', margin: '0', padding: 0 }}>
                {repeatsText}
              </h5>
            </ActivityRepetitionBlock>
          )}
          {isEditable && (
            <ActivityRepetitionBlock>
              <Checkbox
                color="primary"
                size="medium"
                checked={isAllDay}
                onChange={(e) => setIsAllDay(e.target.checked)}
                defaultChecked={false}
              />
              <h6>Activity is all day</h6>
              <Checkbox
                onChange={(e) => setIsRecurring(e.target.checked)}
                color="primary"
                size="medium"
                checked={isRecurring}
                disabled={initialRecurringData}
                defaultChecked={false}
              />
              <h6>Activity is recurring</h6>
            </ActivityRepetitionBlock>
          )}
        </StyledGrouping>
        {isEditable && isRecurring && !initialRecurringData && (
          <>
            <StyledGrouping>
              <Column>
                <Label>Repeat every</Label>
                <div
                  style={{
                    display: 'flex',
                    gap: '30px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                  <Field
                    type="number"
                    size="100%"
                    min={1}
                    formErrors={errors}
                    defaultValue={activityRecurringData.repeatEveryValue}
                    inputRef={register({
                      required: {
                        value: true,
                        message: 'Please enter start time',
                      },
                    })}
                    name="numOfRepetitions"
                  />
                  <FormDropDown
                    style={{
                      marginTop: '1px',
                      marginBottom: '8px',
                      width: '55%',
                    }}
                    isEditable={isEditable}
                    list={repeatPeriodValues}
                    name="Repeats"
                    value={activityRecurringData.repeatEveryUnit}
                    setValue={setRepeatEveryUnit}
                    formErrors={errors}
                  />
                </div>
              </Column>
              {isActivityRepeatedWeekly && (
                <div style={{ width: '48%', height: '100%' }}>
                  <Label>Repeat on</Label>
                  <ActivityCheckGroup
                    disabled={!isEditable}
                    name="weekDays"
                    formErrors={errors}
                    selectedDays={selectedDaysArray}
                    setSelectedDays={setSelectedDaysArray}
                    roundCheckbox
                    noTitle
                  />
                </div>
              )}
            </StyledGrouping>
          </>
        )}
        {isEditable && isRecurring && !initialRecurringData && (
          <StyledGrouping>
            <Column>
              <Label>Ends</Label>
              <StyledGrouping>
                <FormControlLabel
                  className={classes.formControlLabel}
                  label="After this amount of ocurrences?"
                  control={CustomRadioButton('Ocurrence')}
                  name="repeats"
                />
                <Field
                  className={classes.field}
                  type="number"
                  min={1}
                  formErrors={errors}
                  disabled={selectedRadioValue !== 'Ocurrence'}
                  defaultValue={activityRecurringData.recurringEndsAfter}
                  inputRef={register({
                    required: {
                      value: selectedRadioValue === 'Ocurrence',
                      message: '',
                    },
                  })}
                  name="repeatsAmt"
                />
              </StyledGrouping>
            </Column>
            <Column>
              <Label>Ends</Label>
              <StyledGrouping>
                <FormControlLabel
                  className={classes.formControlLabel}
                  label="On this date:"
                  control={CustomRadioButton('Date')}
                  name="repeats"
                />
                <Field
                  className={classes.formControlLabel}
                  label=""
                  disabled={selectedRadioValue !== 'Date'}
                  type="date"
                  min={today}
                  max={activityMaxDate}
                  defaultValue={activityRecurringData.recurringEndsOn}
                  inputRef={register({
                    required: {
                      value: selectedRadioValue === 'Date',
                      message: 'Please enter date',
                    },
                  })}
                  name="SpecificDate"
                  placeholder="Specific date"
                />
              </StyledGrouping>
            </Column>
          </StyledGrouping>
        )}
        <StyledGrouping>
          <TextareaField
            disabled={!isEditable}
            label="Description"
            type="text"
            formErrors={errors}
            defaultValue={description}
            inputRef={register({
              required: {
                value: true,
                message: 'Please enter activity description',
              },
            })}
            name="description"
          />
        </StyledGrouping>

        <StyledGrouping style={{ marginTop: '20px', alignItems: 'start' }}>
          <div style={{ maxWidth: '48%' }}>
            <Label style={labelStyle}>Activity Image</Label>
            <EditableImage
              isEditable={isEditable}
              inputRef={register}
              imageUrl={imageUrl}
              name="image"
            />
          </div>

          {isEditable && (
            <Field
              disabled={!isEditable}
              min={1}
              label="Limit max number of participants (optional)"
              type="number"
              style={{ justifyContent: 'start' }}
              placeholder="Enter a number"
              name="maxMembers"
              inputRef={register}
            />
          )}
        </StyledGrouping>
        {isEditable && (
          <>
            <ActionWrapper>
              <CancelButton
                onClick={() =>
                  changeEditState ? changeEditState(false) : history.goBack()
                }
                type="button"
                style={{ marginRight: '35px' }}>
                Cancel
              </CancelButton>
              <PrimaryButton
                ref={submitButtonRef}
                type="submit"
                disabled={isSubmitting}>
                Save changes
              </PrimaryButton>
            </ActionWrapper>
          </>
        )}
      </Form>
    </StyledGroupCard>
  );
};

export default ActivityDetailsForm;
