import React from 'react';
import styled from 'styled-components';
import { Table, TableBody } from '@material-ui/core';
import Row from './Row';

const StyledTableContainer = styled.div`
  width: auto;
  margin: 0 36px;
`;

const leaderStyle = { fontSize: '20px', width: '100%', height: '69px' };

const DataTable = ({ onEdit = null, onDelete = null, columns, rows }) => {
  return (
    <StyledTableContainer>
      <Table>
        <TableBody style={{ border: 'none', width: '100%' }}>
          {rows.map((row, index) => (
            <Row
              aria-describedby={`row-${index}`}
              key={`row-${index}`}
              number={index + 1}
              rowData={row}
              columns={columns}
              onEdit={onEdit}
              onDelete={onDelete}
              style={index < 3 ? leaderStyle : ''}
              font={index < 3 ? '20px' : '14px'}
            />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default DataTable;
