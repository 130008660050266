import user from './user';
import company from './company';
import role from './role';
import invite from './invite';
import http from './http';
import group from './group';
import event from './event';
import db from './db';

const serverErrors = {
  user,
  company,
  role,
  invite,
  http,
  group,
  event,
  db,
};

export { serverErrors };
