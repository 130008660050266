import React from 'react';
import { Route, s } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';

const SuperAdminRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  return user ? (
    <Route
      {...rest}
      render={(props) =>
        user.isSuperAdmin ? <Component {...props} /> : <s to="/app/invites" />
      }
    />
  ) : (
    <Route {...rest} render={<s to="/login" />} />
  );
};

export default SuperAdminRoute;
