import React from 'react';
import styled from 'styled-components/macro';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ErrorText } from '../../common';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    border: 'none',
  },
}));

const Container = styled.div`
  margin: 8px 0;
`;

const StyledSelect = styled(Select)`
  width: 384px;
  height: 52px;
  font-size: 16px;
  /* padding-left: 16px; */
  box-sizing: border-box;
  border-radius: 8px;
`;

const SelectList = ({
  options,
  value,
  error,
  onChange,
  label,
  inputRef = () => {},
  name,
  width = '200px',
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <Container>
      <FormControl variant="outlined" className={classes.formControl}>
        <StyledSelect
          disabled={disabled}
          style={{ width, borderRadius: '8px' }}
          value={value}
          onChange={onChange}
          label={label}
          name={name}
          inputRef={inputRef()}>
          {options.map((item) => (
            <MenuItem value={item.value} key={item.label}>
              {item.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      <ErrorText>{error}</ErrorText>
    </Container>
  );
};

export default SelectList;
