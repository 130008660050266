import React from 'react';
import styled from 'styled-components/macro';
import _get from 'lodash/get';

import Input, { TextArea } from './common/Input';
import Label from './common/Label';
import { ErrorText } from '../../common';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: auto;
`;

const Field = (props) => {
  const {
    label,
    inputRef,
    formErrors,
    name,
    placeholder,
    size,
    type,
    min,
    max,
  } = props;
  const fieldError = _get(formErrors, name);

  const inputSize = size ? { width: size } : {};
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      {type === 'textarea' ? (
        <TextArea
          style={inputSize}
          {...props}
          ref={inputRef}
          hasError={fieldError}
          placeholder={placeholder}
        />
      ) : (
        <Input
          type={type}
          style={inputSize}
          {...props}
          min={min}
          max={max}
          ref={inputRef}
          hasError={fieldError}
          placeholder={placeholder}
        />
      )}
      {fieldError && <ErrorText>{fieldError.message}</ErrorText>}
    </Wrapper>
  );
};

export default Field;
