import Auth from './Auth';
import userErrors from '../constants/errors/user';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => ({ Authorization: `Bearer ${Auth.getToken()}` });

const getRequestHeaders = (isJSON = true) => {
  return {
    'Content-Type': isJSON ? 'application/json' : 'text/html',
    ...getAuthHeader(),
  };
};

const fetchApi = async (url, options = {}) => {
  return fetch(url, options)
    .then(async (r) => {
      if (r.status < 200 || r.status >= 300) {
        const { code, statusText, data } = await r.json();
        const error = new Error(statusText);
        error.status = r.status;
        error.code = code;
        error.data = data;
        throw error;
      }
      return r.json();
    })
    .catch(async (error) => {
      if (
        error.status === 401 &&
        error.code !== userErrors.lacks_first_last_name
      ) {
        try {
          const refreshTokenResponse = await fetch(`${API_URL}/reauth`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: Auth.getToken() }),
          });

          if (refreshTokenResponse.status === 401) {
            throw new Error('unauthorized');
          }

          const { token: newToken } = await refreshTokenResponse.json();

          Auth.setToken(newToken);

          return fetchApi(url, {
            ...options,
            headers: { ...options.headers, ...getAuthHeader() },
          });
        } catch (e) {
          Auth.deleteToken();
          Auth.deleteUser();
          window.location.replace('/login');
        }
      }

      throw error;
    });
};

class Fetch {
  static getJSON = (url, headers = {}) =>
    fetchApi(`${API_URL}${url}`, {
      headers: { ...getRequestHeaders(), ...headers },
      cache: 'no-cache',
    });

  static postJSON = (url, data = {}, headers = {}) =>
    fetchApi(`${API_URL}${url}`, {
      method: 'POST',
      headers: { ...getRequestHeaders(), ...headers },
      body: JSON.stringify(data),
    });

  static putJSON = (url, data = {}, headers = {}) =>
    fetchApi(`${API_URL}${url}`, {
      method: 'PUT',
      headers: { ...getRequestHeaders(), ...headers },
      body: JSON.stringify(data),
    });

  static deleteJSON = (url, data = {}, headers = {}) =>
    fetchApi(`${API_URL}${url}`, {
      method: 'DELETE',
      headers: { ...getRequestHeaders(), ...headers },
      body: JSON.stringify(data),
    });

  static uploadFile = (url, file = null, headers = {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return fetchApi(`${API_URL}${url}`, {
      method: 'POST',
      headers: { ...getAuthHeader(), ...headers },
      body: formData,
    });
  };
}

export default Fetch;
