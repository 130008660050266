import React from 'react';

import Popper from '@material-ui/core/Popper';

function PopUp({
  anchorEl,
  id,
  handlePopperClose,
  isPopperOpen,
  displayComponent,
}) {
  return (
    <Popper
      id={id}
      open={isPopperOpen}
      anchorEl={anchorEl}
      onClose={handlePopperClose}
      anchororigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      {displayComponent}
    </Popper>
  );
}

export default PopUp;
