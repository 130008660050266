import React from 'react';
import styled from 'styled-components/macro';
import { makeStyles } from '@material-ui/core/styles';
import MuiModal from '@material-ui/core/Modal';
import Grow from '@material-ui/core/Grow';

import Close from '../../assets/icons/Close';
import { theme as themeConfig } from '../../theme/Theme';

const ModalContentWrapper = styled.div`
  outline: none;
  position: relative;
`;

const IconWrapper = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  :hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Modal = (props) => {
  const { children, onClose, open } = props;
  const classes = useStyles();

  return (
    <MuiModal {...props} className={classes.modal}>
      <Grow in={open} {...(open ? { timeout: 310 } : {})}>
        <ModalContentWrapper>
          {onClose && (
            <IconWrapper onClick={() => onClose()}>
              <Close color={themeConfig.colors.lightGrey2} />
            </IconWrapper>
          )}
          {children}
        </ModalContentWrapper>
      </Grow>
    </MuiModal>
  );
};

export default Modal;
