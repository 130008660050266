import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.input`
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid
    ${({ isChecked, theme }) =>
      isChecked ? theme.colors.green : theme.colors.lightGrey};
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background-color: ${({ isChecked, theme }) =>
    isChecked ? theme.colors.green : 'white'};
`;
const Label = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  color: ${({ isChecked }) => (!isChecked ? 'black' : 'white')};
`;

const RoundCheckbox = ({ checked, label, onChange }) => {
  const [isChecked, setisChecked] = useState(checked);
  const changeCheckboxState = () => {
    setisChecked((prev) => !prev);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Wrapper
        isChecked={isChecked}
        onClick={changeCheckboxState}
        type="checkbox"
        onChange={onChange}
      />
      <Label isChecked={isChecked}>{label}</Label>
    </div>
  );
};

export default RoundCheckbox;
