import styled, { css } from 'styled-components/macro';

const activeStyle = css`
  color: ${({ theme }) => theme.colors.white};
`;

const hoverStyle = css`
  color: ${({ theme }) => theme.colors.electricLime};
`;

const TextButton = styled.button`
  display: flex;
  justify-content: left;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  border: none;
  outline: none;
  margin: 3px 0;
  color: ${({ theme }) => theme.colors.grey};
  padding: 2px 0px;
  text-decoration: none;
  text-align: left;
  align-items: center;
  border-radius: 4px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  & > * {
    margin-right: 10px;
  }

  :hover {
    ${hoverStyle}
  }
  cursor: pointer;
  transition: 0.3s ease;
  ${(p) => p.$isActive && activeStyle}
  ${(span) => span.$isActive && activeStyle}
`;

export default TextButton;
