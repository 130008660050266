import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { PrimaryButton } from '../ui/buttons';
import { FormDropDown, MultipleTextField } from '../ui/fields';

import Form from './styles/Form';
import Title from './styles/Title';
import { patterns } from '../validation/patterns';
import { useIsSubmitting } from '../../hooks/useIsSubmitting';
import { Fetch } from '../../services';
import { errorNotification } from '../ui/notifications/ErrorNotification';
import Label from '../ui/fields/common/Label';

const ActionWrapper = styled.span`
  display: flex;
  justify-content: center;
`;

const StyledInviteCard = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  height: auto;
  width: auto;
  padding: 36px;
  border-radius: 8px;
`;

const validateDropdowns = (list, setError, clearErrors) => {
  clearErrors();
  list.forEach((e) => {
    if (e.value === undefined) {
      setError(`${e.name}`, {
        type: 'text',
        message: `Choose ${e.name}`,
      });
    }
  });
};

const validateFormEmails = (emails) => {
  return emails.find((e) => e.length > 0);
};

/**
 * Form used for the creation of company invites
 * @param {{ onCreate: (data: any) => Promise, onSuccess: () => void}} paramsObject  - Object containing functions to be executed onCreate of invites and the success of creation
 */
const InviteForm = ({ onCreate, onSubmitEnd, companyId }) => {
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [locations, setLocations] = useState();
  const [departments, setDepartments] = useState();
  const {
    register,
    handleSubmit: formHandleSubmit,
    errors,
    setError,
    clearErrors,
  } = useForm();
  const { isSubmitting, startSubmit, endSubmit } = useIsSubmitting({
    onSubmitEnd,
  });

  const getLocations = () => {
    Fetch.getJSON(`/company/${companyId}/location?limit=all`)
      .then((data) => {
        setLocations(
          data.items.map((location) => {
            return { value: location.id, name: location.name };
          }),
        );
      })
      .catch(() => errorNotification('Error while getting locations'));
  };

  const getDepartments = () => {
    Fetch.getJSON(`/company/${companyId}/department?limit=all`)
      .then((data) => {
        setDepartments(
          data.items.map((department) => {
            return { value: department.id, name: department.name };
          }),
        );
      })
      .catch(() => errorNotification('Error while getting departments'));
  };

  useEffect(() => {
    getLocations();
    getDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (formData) => {
    if (!validateFormEmails(formData.emails)) {
      errorNotification('No emails entered');
      return;
    }
    startSubmit();
    await onCreate({
      departmentId: selectedDepartment,
      locationId: selectedLocation,
      ...formData,
    });
    endSubmit();
  };

  const handleSubmit = (e) => {
    validateDropdowns(
      [
        { value: selectedLocation, name: 'Location' },
        { value: selectedDepartment, name: 'Department' },
      ],
      setError,
      clearErrors,
    );

    formHandleSubmit(onSubmit)(e);
  };

  return (
    <StyledInviteCard>
      <Form onSubmit={handleSubmit}>
        <Title>Invite Employees</Title>
        <FormDropDown
          isEditable
          label="Location"
          list={locations || []}
          name="Location"
          style={{ width: '384px' }}
          ref={register({
            required: 'Please enter location',
          })}
          value={selectedLocation}
          setValue={setSelectedLocation}
          formErrors={errors}
        />
        <br />
        <FormDropDown
          isEditable
          label="Deparment"
          list={departments || []}
          name="Department"
          style={{ width: '384px' }}
          ref={register({
            required: 'Please enter department',
          })}
          value={selectedDepartment}
          setValue={setSelectedDepartment}
          formErrors={errors}
        />
        <br />
        <>
          <Label>Emails:</Label>
          <MultipleTextField
            name="emails"
            inputRef={register}
            errors={errors}
            placeholder="Enter email"
            pattern={{
              value: patterns.email,
              message: "That's not an email value",
            }}
          />
        </>
        <ActionWrapper>
          <PrimaryButton type="submit" disabled={isSubmitting}>
            Send Invites
          </PrimaryButton>
        </ActionWrapper>
      </Form>
    </StyledInviteCard>
  );
};

export default InviteForm;
