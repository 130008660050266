import styled from 'styled-components/macro';
import LinkButtonBase from './LinkButtonBase';

const LinkButton = styled.a`
  width: 100%;
  border: none;
  outline: none;
  ${LinkButtonBase}
`;

export default LinkButton;
