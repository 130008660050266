import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { InterExtraLight, InterRegular, InterSemiBold } from '../assets/font';

/*
  color naming: [colorName][opacityPercentage][Darker|Lighter|(original has no postfix)]
*/
export const theme = {
  colors: {
    green: '#2E6B61',
    darkGreen: '#1D423C',
    lightGreen: '#419385',
    paleGreen: '#B5DBD0',
    electricLime: '#AAFF3D',
    lightGrey: '#B2B5B8',
    lighterGray: '#B8BCC4',
    grey: '#6E7880',
    darkGrey: '#40474D',
    black: '#272B30',
    white: '#ffffff',
    red: '#B81D1D',
  },
};

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter', sans-serif;
    src: url('${InterExtraLight}') format('woff'),
      url('${InterRegular}') format('woff'),
      url('${InterSemiBold}') format('woff'),

  }
  body {
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    margin: 0;
    color: ${theme.colors.black};
  }

  h1 {
    font-size: 36px;
    line-height: 43px;
    margin: 20px 0px;
    letter-spacing: 2%;
  }

  h2 {
    font-size: 28px;
    line-height: 33px;
  }

  h3 {
    font-size: 24px;
    line-height: 29px;
  }

  h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  h5 {
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
  }

  h6 {
    font-size: 16px;
    line-height: 21px;
    font-weight: 200;
  }

  p {
    font-weight: 200;
    font-size: 14px;
    line-height: 17px;
  }

  a {
    color: ${theme.colors.green};

    :hover {
      color: ${theme.colors.darkGreen}
    }

    :active {
      color: ${theme.colors.electricLime}
    }
  }
`;

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.colors.green,
    },
    secondary: {
      main: theme.colors.lightGrey,
    },
  },
});

const Theme = ({ children }) => (
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  </MuiThemeProvider>
);

export default Theme;
