import { DateTime } from 'luxon';

const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/*
  Luxon uses BCP 47 codes for setting time locales, List of locales: https://github.com/libyal/libfwnt/wiki/Language-Code-identifiers
*/
export const formatDate = (ISODateString) =>
  DateTime.fromISO(ISODateString).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS,
  );

export const formatDataForChartDisplay = (period, data) => {
  switch (period) {
    case 'year':
      return months.map((month) => {
        return { y: data[month]?.count || 0, x: month, ...data[month] };
      });
    case 'month': {
      return weeks.map((week) => {
        return { y: data[week]?.count || 0, x: week, ...data[week] };
      });
    }
    case 'week': {
      return weekDays.map((day) => {
        return { y: data[day]?.count || 0, x: day, ...data[day] };
      });
    }

    default: {
      const objectArray = Object.entries(data);
      return objectArray.map(([key, value]) => {
        return { x: key, y: value?.count, ...value };
      });
    }
  }
};
