import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { LinkButton, PrimaryButton } from '../ui/buttons';
import { Field } from '../ui/fields';
import Form from './styles/Form';
import Title from './styles/Title';
import { patterns } from '../validation/patterns';
import { Spinner } from '../ui';
import { ErrorText } from '../common';
import { serverErrors } from '../../constants/errors';
import { getErrorMessageWith } from '../../utils/getErrorMessageWith';
import Subtitle from './styles/Subtitle';

const ActionWrapper = styled.span`
  display: flex;
  justify-content: center;
  margin: 36px 0;
  width: 100%;
`;

const FormCard = styled.div`
  display: flex;
  justify-content: center;
  width: 400px;
  height: 100%;
`;

const getErrorMessage = getErrorMessageWith({
  [serverErrors.user.not_found]: 'The email you entered is not registered.',
  [serverErrors.invite.not_found]: 'The email you entered is not registered.',
  [serverErrors.user.passwordless_token_invalid]:
    'Your login token is invalid. Request a new one by entering your email above.',
  [serverErrors.http.bad_request]:
    'An unexpected error happened. Please contact your site administrator.',
});

const LoginForm = ({
  onSubmit = () => {},
  onBackToLogin = () => {},
  loading,
  error,
  success,
  isSubmitDisabled,
}) => {
  const { register, handleSubmit, errors } = useForm();
  let content = (
    <>
      <Title align="left">Admin Login</Title>
      <Field
        label="Email"
        type="text"
        formErrors={errors}
        style={{ width: '304px' }}
        inputRef={register({
          pattern: {
            value: patterns.email,
            message: 'Please enter a valid email value',
          },
          required: { value: true, message: 'Please enter email' },
        })}
        name="email"
      />
      <ActionWrapper>
        <PrimaryButton disabled={isSubmitDisabled} type="submit">
          Request login link
        </PrimaryButton>
      </ActionWrapper>
    </>
  );

  if (success) {
    content = (
      <>
        <Title align="left">Check your inbox!</Title>
        <Subtitle>The login link was sent to your email.</Subtitle>
        <ActionWrapper>
          <LinkButton onClick={onBackToLogin}>Back to login</LinkButton>
        </ActionWrapper>
      </>
    );
  }

  return (
    <FormCard>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {loading ? <Spinner /> : content}
        {error && <ErrorText>{getErrorMessage(error)}</ErrorText>}
      </Form>
    </FormCard>
  );
};

export default LoginForm;
