const STORAGE_KEYS = {
  token: 'token',
  user: 'user',
  allCompanies: 'allCompanies',
  selectedCompany: 'selectedCompany',
};

class Auth {
  static getToken = () => {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.token));
  };

  static setToken = (token) =>
    localStorage.setItem(STORAGE_KEYS.token, JSON.stringify(token));

  static deleteToken = () => localStorage.removeItem(STORAGE_KEYS.token);

  static getAllCompanies = () => {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.allCompanies));
  };

  static setAllCompanies = (data) =>
    localStorage.setItem(STORAGE_KEYS.allCompanies, JSON.stringify(data));

  static deleteAllCompanies = () =>
    localStorage.removeItem(STORAGE_KEYS.allCompanies);

  static getSelectedCompany = () => {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.selectedCompany));
  };

  static setSelectedCompany = (data) =>
    localStorage.setItem(STORAGE_KEYS.selectedCompany, JSON.stringify(data));

  static deleteSelectedCompany = () =>
    localStorage.removeItem(STORAGE_KEYS.selectedCompany);

  static getUser = () => {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.user));
  };

  static setUser = (user) =>
    localStorage.setItem(STORAGE_KEYS.user, JSON.stringify(user));

  static deleteUser = () => localStorage.removeItem(STORAGE_KEYS.user);
}

export default Auth;
