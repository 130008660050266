import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { loginUser, useAuth } from '../../components/auth';

import { useQuery } from '../../hooks';

import YoudoLogo from '../../assets/images/youdologo.png';
import { resetStatusState } from '../../components/auth/authActions';
import { LoginForm } from '../../components/forms';

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const LoginCard = styled.div`
  display: flex;
  width: 680px;
  height: 450px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 8px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 280px;
  border-radius: 8px 0 0 8px;
  background-color: ${({ theme }) => theme.colors.green};
  margin-right: 0;

  h3 {
    color: white;
    font-weight: 400;
    margin: 0;
  }
`;

const Logo = styled.img`
  width: 195px;
`;

const FormCard = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Login = () => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const queryTokenKey = query.get('t');

  const { error, user, loading, dispatch, success, token } = useAuth();

  useEffect(() => {
    if (queryTokenKey && !user && !token) {
      loginUser(dispatch, queryTokenKey, null, history);
    }
  }, [queryTokenKey, history, dispatch, token, user]);

  if (user) {
    return user.isSuperAdmin ? (
      <Redirect to="/app/companies" />
    ) : (
      <Redirect to="/app/company" />
    );
  }

  const onLogin = async (formData) => {
    const { email } = formData;
    setIsSubmitDisabled(true);
    await loginUser(dispatch, queryTokenKey, email);
    setIsSubmitDisabled(false);
  };

  const onBackToLogin = () => {
    resetStatusState(dispatch);
  };

  return (
    <Container>
      <LoginCard>
        <LogoWrapper>
          <Logo src={YoudoLogo} />
          <h3>Dashboard</h3>
        </LogoWrapper>
        <FormCard>
          <LoginForm
            onSubmit={onLogin}
            loading={loading}
            error={error}
            success={success}
            onBackToLogin={onBackToLogin}
            isSubmitDisabled={isSubmitDisabled}
          />
        </FormCard>
      </LoginCard>
    </Container>
  );
};

export default Login;
