import styled from 'styled-components/macro';
import PrimaryButtonBase from './ButtonBase';

const CancelButton = styled.button`
  ${PrimaryButtonBase}
  display: flex;
  background-color: transparent;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.darkGreen};
  color: ${({ theme }) => theme.colors.darkGreen};
  padding: 0;

  :hover {
    border: 2px solid ${({ theme }) => theme.colors.electricLime};
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.electricLime};
  }
  transition: 0.1s ease;
`;

export default CancelButton;
