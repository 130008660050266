class Log {
  static out = (data) => {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test'
    ) {
      console.info(data);
    }
  };
}

export default Log;
