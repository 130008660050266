import styled from 'styled-components/macro';
import PrimaryButtonBase from './ButtonBase';

const WarningButton = styled.button`
  ${PrimaryButtonBase}
  background-color: red;
  color: white;
`;

export default WarningButton;
