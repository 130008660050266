import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import _get from 'lodash/get';
import { ErrorText } from '../../common';
import { AddCircle } from '../../../assets/icons';
import { theme as themeConfig } from '../../../theme/Theme';

const StyledGroupPhoto = styled.img`
  width: ${({ width }) => width || '378px'};
  height: auto;
  border-radius: 8px;
  cursor: ${({ isEditable }) => (isEditable ? 'pointer' : 'default')};
`;

const UploadAction = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #e5e5e5;
  padding: 48px 24px;
  margin: 14px 0;
  box-sizing: border-box;
  width: ${({ width }) => width || '378px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.darkGreen};
  svg {
    margin-right: 4px;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const EditableImage = ({
  inputRef,
  formErrors,
  name,
  isEditable,
  imageUrl,
  width,
}) => {
  const [imagePreview, setImagePreview] = useState();
  const fieldError = _get(formErrors, name);
  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const loadImage = () => {
    if (isEditable) {
      fileInputRef.current.click();
    }
  };

  return (
    <div style={{ widht: '100%' }}>
      {imagePreview || imageUrl ? (
        <StyledGroupPhoto
          width={width}
          isEditable={isEditable}
          src={imagePreview || imageUrl}
          onClick={loadImage}
        />
      ) : (
        <UploadAction width={width} onClick={loadImage}>
          <AddCircle color={themeConfig.colors.darkGreen} />
          Upload Photo
        </UploadAction>
      )}
      {fieldError && <ErrorText>{fieldError.message}</ErrorText>}

      <FileInput
        ref={(e) => {
          fileInputRef.current = e;
          inputRef(e);
        }}
        onChange={handleFileChange}
        type="file"
        name={name}
      />
    </div>
  );
};

export default EditableImage;
