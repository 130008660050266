const db = {
  connection: 1000,
  read: 1000,
  write: 1001,
  delete: 1002,
  internal: 1003,
  query: 1004,
};

export default db;
