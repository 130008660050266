import { formatDataForChartDisplay } from './formatDate';

export const weekArray = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const repeatPeriodValues = [
  { name: 'Day', tag: 'days' },
  { name: 'Week', tag: 'weeks' },
  { name: 'Month', tag: 'months' },
  { name: 'Year', tag: 'years' },
];

export const repeatDaysTextArray = (weekDays) =>
  weekDays && weekDays.length > 1
    ? weekDays.map((x) => ` ${weekArray[x]}`)
    : weekArray[weekDays];

export const repeatEnds = (endDate) => {
  if (Number(endDate)) {
    return `for ${endDate} ocurrences`;
  }
  return `until ${endDate}`;
};

export const repeatsText = (weekDays, repeatsAmt, endDate) => {
  if (repeatsAmt === 'weeks')
    return `Weekly on ${repeatDaysTextArray(weekDays)} ${repeatEnds(endDate)}`;
  return `Repeats every ${repeatPeriodValues
    .find((el) => el.tag === repeatsAmt)
    ?.name.toLowerCase()} ${repeatEnds(endDate)}`;
};

export const formatChartData = (data = [], period, countName) => {
  const dataObj = {};
  data.forEach((v) => {
    dataObj[v.timeFrame] = {
      count: v[countName],
      topCompany: v.topCompany,
      topEmployee: v.topEmployee,
    };
  });

  return formatDataForChartDisplay(period, dataObj);
};
