import React, { Suspense } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { CompanyProvider } from '../../components/company/CompanyContext';
import AppFallback from './AppFallback';

const AppPage = React.lazy(() => import('./AppPage'));

const AppWithSuspense = () => {
  return (
    <Suspense fallback={<AppFallback />}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <CompanyProvider>
          <AppPage />
        </CompanyProvider>
      </MuiPickersUtilsProvider>
    </Suspense>
  );
};

export default AppWithSuspense;
