import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import _get from 'lodash/get';
import { CancelButton, PrimaryButton, TextButton } from '../ui/buttons';
import { Field } from '../ui/fields';
import Form from './styles/Form';
import { useIsSubmitting } from '../../hooks/useIsSubmitting';
import Title from './styles/Title';
import { patterns } from '../validation/patterns';

const ActionWrapper = styled.span`
  display: flex;
  justify-content: space-between;
`;

const StyledGroupCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: auto;
  width: auto;
  padding: 36px;
  border-radius: 8px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  border: 1px solid ${({ theme }) => theme.colors.lighterGray};
  border-radius: 8px;
  margin-bottom: 8px;
  object-fit: cover;
`;

const LogoActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
`;

const FileInput = styled.input`
  display: none;
`;

const StyledTextButton = styled(TextButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.green};
  font-size: 16px;
  text-decoration: underline;
`;

const CompanyForm = ({
  initialValues,
  onUpdate,
  onCreate,
  onSubmitEnd,
  onClose,
}) => {
  const [imagePreview, setImagePreview] = useState({
    url: initialValues?.imageUrl,
    file: null,
  });
  const { register, handleSubmit, errors } = useForm();
  const { isSubmitting, startSubmit, endSubmit } = useIsSubmitting({
    onSubmitEnd,
  });

  const fileInput = useRef(initialValues?.imageUrl);

  const OpenImagePicker = () => {
    fileInput.current.click();
  };

  const updateOrCreateCompany = (formData) =>
    onCreate ? onCreate(formData) : onUpdate(formData);

  const onSubmit = async (formData) => {
    startSubmit();
    await updateOrCreateCompany({ ...formData, image: imagePreview.file });
    endSubmit();
  };

  const handleFileChange = (e) => {
    setImagePreview({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  return (
    <StyledGroupCard>
      <Form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(onSubmit)}>
        <Title>{onCreate ? 'Create' : 'Edit'} company</Title>
        <Field
          label="Company Name"
          type="text"
          defaultValue={initialValues ? _get(initialValues, 'name') : ''}
          formErrors={errors}
          inputRef={register({
            required: { value: true, message: 'Please enter company name' },
          })}
          name="name"
        />
        {onCreate && (
          <>
            <br />
            <Field
              label="Admin Email"
              type="text"
              formErrors={errors}
              inputRef={register({
                required: {
                  value: true,
                  message: onCreate
                    ? 'Please enter email'
                    : 'Please enter email of the admin user',
                },
                pattern: {
                  value: patterns.email,
                  message: "That's not an email value",
                },
              })}
              name="adminEmail"
            />
          </>
        )}
        <br />
        <LogoWrapper>
          <Image src={imagePreview.url} />
          <LogoActionsWrapper>
            <div>Company Image</div>
            <FileInput
              type="file"
              ref={(event) => {
                register(event);
                fileInput.current = event;
              }}
              onChange={handleFileChange}
              name="image"
            />
            <StyledTextButton type="button" onClick={OpenImagePicker}>
              Upload {onCreate ? 'image' : 'new'}
            </StyledTextButton>
          </LogoActionsWrapper>
        </LogoWrapper>
        <br />
        <ActionWrapper>
          <CancelButton type="button" onClick={onClose}>
            Cancel
          </CancelButton>
          <PrimaryButton type="submit" disabled={isSubmitting}>
            {onCreate ? 'Create company' : 'Save changes'}
          </PrimaryButton>
        </ActionWrapper>
      </Form>
    </StyledGroupCard>
  );
};

export default CompanyForm;
