import { IconButton as MuiIconButton } from '@material-ui/core';
import React from 'react';

const IconButton = ({ onClick, icon, disabled, end }) => {
  const alignStyle = end ? { alignSelf: 'flex-end' } : {};
  return (
    <MuiIconButton
      onClick={onClick}
      type="button"
      disabled={disabled}
      style={alignStyle}>
      {icon}
    </MuiIconButton>
  );
};

export default IconButton;
