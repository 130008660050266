import styled from 'styled-components/macro';

const Form = styled.form`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export default Form;
