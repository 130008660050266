import { Auth } from '../../services';
import { actionTypes } from './actionTypes';

const user = Auth.getUser();

const token = Auth.getToken();

export const initialState = {
  user,
  token,
  loading: false,
  isFirstLogin: true,
  success: false,
  error: null,
};

export const AuthReducer = (initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_LOGIN_LINK_START:
      return {
        ...initialAuthState,
        loading: true,
      };
    case actionTypes.REQUEST_LOGIN_LINK_ERROR:
      return {
        ...initialAuthState,
        loading: false,
        error: action.error,
      };
    case actionTypes.REQUEST_LOGIN_LINK_SUCCESS:
      return {
        ...initialAuthState,
        error: null,
        success: true,
        loading: false,
      };
    case actionTypes.REQUEST_LOGIN:
      return {
        ...initialAuthState,
        loading: true,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...initialAuthState,
        error: null,
        success: true,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      };
    case actionTypes.LOGOUT:
      return {
        user: null,
        token: null,
        loading: false,
        isFirstLogin: false,
        error: null,
      };
    case actionTypes.LOGIN_ERROR:
      return {
        ...initialAuthState,
        loading: false,
        error: action.error,
      };
    case actionTypes.IS_FIRST_LOGIN:
      return {
        ...initialAuthState,
        loading: false,
        isFirstLogin: true,
        error: action.error,
      };
    case actionTypes.RESET_STATUS_STATE:
      return {
        ...initialAuthState,
        loading: false,
        success: false,
        error: null,
      };
    case actionTypes.NON_EXISTING_USER:
      return {
        ...initialAuthState,
        loading: false,
        success: false,
        error: action.error,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
