import styled from 'styled-components/macro';

const Subtitle = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`;

export default Subtitle;
