import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';

export const Header = ({ columns = [], hasActions, actionName = '' }) => {
  const styling = {
    fontSize: '16px',
    boxSizing: 'border-box',
    fontWeight: '600',
    padding: '22px 25px',
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((columnCell) => {
          const { align, headerName, field } = columnCell;
          return (
            <TableCell style={styling} align={align} key={field}>
              {headerName}
            </TableCell>
          );
        })}
        {hasActions && (
          <TableCell
            style={{ ...styling, textAlign: 'center', paddingRight: '98px' }}>
            {actionName}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
