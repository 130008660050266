import { useState, useEffect } from 'react';

export const useIsSubmitting = ({ onSubmitEnd = () => {} }) => {
  const [isSubmitting, setIsSubmitting] = useState(null);
  const startSubmit = () => setIsSubmitting(true);

  const endSubmit = async () => {
    // You can uncomment the line bellow to test the functionality of button disable while form submitting is in progress
    // await new Promise((resolve) => setTimeout(resolve, 3000));
    setIsSubmitting(false);
  };
  useEffect(() => {
    if (isSubmitting === false) {
      onSubmitEnd();
    }
  }, [isSubmitting, onSubmitEnd]);
  return {
    isSubmitting,
    startSubmit,
    endSubmit,
  };
};
