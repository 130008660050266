import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  width: 34px;
  height: 34px;
  outline: none;
  object-fit: cover;
  border-radius: 50%;
`;

const ImagePlaceholder = styled.div`
  width: 34px;
  height: 34px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-radius: 50%;
  margin: 0 auto;
`;

const ImagePreview = ({ src }) =>
  src ? <Image src={src} /> : <ImagePlaceholder />;
export default ImagePreview;
