import styled from 'styled-components/macro';

const SubSubtitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.mediumGrey};
  font-size: 14px;
  font-weight: 400;
`;

export default SubSubtitle;
