import React from 'react';
import styled from 'styled-components/macro';
import _get from 'lodash/get';

import Label from './common/Label';
import Textarea from './common/Textarea';
import { ErrorText } from '../../common';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  width: 100%;
  margin: 20px 0px;
`;

const TextAreaField = (props) => {
  const { label, inputRef, formErrors, name, placeholder } = props;
  const fieldError = _get(formErrors, name);
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <Textarea
        rows="4"
        style={{ fontFamily: 'inherit' }}
        {...props}
        ref={inputRef}
        hasError={fieldError}
        placeholder={placeholder}
      />
      {fieldError && <ErrorText>{fieldError.message}</ErrorText>}
    </Wrapper>
  );
};

export default TextAreaField;
