import { Slide, toast } from 'react-toastify';

export const errorNotification = (errorMessage) => {
  toast(errorMessage, {
    position: 'bottom-center',
    type: 'error',
    theme: 'colored',
    autoClose: 5000,
    style: {
      color: '#ffffff',
      textAlign: 'center',
    },
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    pauseOnFocusLoss: false,
    transition: Slide,
  });
};
