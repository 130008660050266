import styled from 'styled-components/macro';

const Input = styled.input`
  width: 384px;
  height: 52px;
  font-size: 16px;
  font-family: inherit;
  padding-left: 16px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px
    ${(p) => (p.hasError ? p.theme.colors.red : p.theme.colors.lighterGray)}
    solid;
  outline: none;
  :focus {
    border: 1px ${({ theme }) => theme.colors.green} solid;
  }
  transition: 0.2s ease;
`;

export const TextArea = styled.textarea`
  width: 384px;
  height: 52px;
  font-size: 16px;
  font-family: inherit;
  padding-left: 16px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px
    ${(p) => (p.hasError ? p.theme.colors.red : p.theme.colors.lightGray)} solid;
  outline: none;
  :focus {
    border: 1px ${({ theme }) => theme.colors.green} solid;
  }
  transition: 0.2s ease;
  min-width: 384px;
  max-width: 384px;
  padding-left: 5px;
`;

export default Input;
