/**
 *
 * A object containing key value pairs, where keys are error codes and values are
 * error messages that should be displayed to the user
 * @typedef {{ [key: string]: string }} ErrorMessageObjectType
 */

/**
 * Function used to map error codes to error message
 * @param {ErrorMessageObjectType} errorMessageObject  - object with code to error message map
 */
export const getErrorMessageWith = (errorMessageObject) => ({ code }) =>
  errorMessageObject[code] || 'An error happened';
