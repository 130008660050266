import styled from 'styled-components/macro';

const Textarea = styled.textarea`
  resize: none;
  padding: 12px 8px;
  border-radius: 8px;
  border: 1px
    ${(p) => (p.hasError ? p.theme.colors.red : p.theme.colors.lighterGray)}
    solid;
  outline: none;
  :focus {
    border: 1px ${({ theme }) => theme.colors.green} solid;
  }
  transition: 0.2s ease;
`;

export default Textarea;
