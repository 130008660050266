import React, { useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import _get from 'lodash/get';
import RoundCheckbox from '../ui/fields/common/RoundCheckbox';
import { ErrorText } from '../common';

const addDay = (array, element) => {
  return [...array, element].sort();
};

const removeDay = (array, element) => {
  return array.filter((item) => item !== element);
};

const ActivityCheckGroup = ({
  disabled,
  handleCheckboxChange = () => {},
  weekDays,
  roundCheckbox,
  noTitle,
  formErrors,
  selectedDays,
  setSelectedDays,
  name,
}) => {
  const options = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];
  const fieldError = _get(formErrors, name);

  const onChange = (e, index) => {
    if (e.target.checked) {
      setSelectedDays((prev) => addDay(prev, index));
    } else {
      setSelectedDays((prev) => removeDay(prev, index));
    }
  };

  useEffect(() => {
    if (roundCheckbox) {
      handleCheckboxChange(selectedDays);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays]);

  return (
    <FormControl name="repeats">
      {!noTitle && <FormLabel id="repeatsLabel">Repeat on:</FormLabel>}
      <FormGroup
        row
        aria-labelledby="repeatDaysGroup"
        name="position"
        defaultValue="top">
        {options.map((day, index) => {
          return (
            <FormControlLabel
              disabled={disabled}
              key={index}
              value={index}
              style={{ margin: '7px' }}
              control={
                roundCheckbox ? (
                  <RoundCheckbox
                    checked={selectedDays.includes(index)}
                    onChange={(e) => onChange(e, index)}
                    label={day[0]}
                  />
                ) : (
                  <Checkbox
                    color="primary"
                    size="small"
                    onChange={handleCheckboxChange}
                    defaultChecked={weekDays?.includes(index)}
                  />
                )
              }
              label={roundCheckbox ? '' : day}
              labelPlacement="top"
              name="reapeat"
            />
          );
        })}
      </FormGroup>
      {fieldError && <ErrorText>{fieldError.message}</ErrorText>}
    </FormControl>
  );
};

export default ActivityCheckGroup;
