import React from 'react';
import { useForm } from 'react-hook-form';
// import _get from 'lodash/get';
import styled from 'styled-components/macro';
import { PrimaryButton, CancelButton } from '../ui/buttons';
import { Field, TextareaField } from '../ui/fields';
import Form from './styles/Form';
import { useIsSubmitting } from '../../hooks/useIsSubmitting';
import EditableImage from '../ui/fields/EditableImage';

const ActionWrapper = styled.span`
  display: flex;
  justify-content: center;
`;

const StyledGroupCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  height: auto;
  width: 800px;
  padding: 36px 200px;
  border-radius: 8px;
`;

// const StyledInfoWrapper = styled.div`
//   display: flex;
//   align-content: center;
//   justify-content: space-between;
//   width: 100%;
//   height: auto;
//   margin-bottom: 25px;
// `;

// const StyledInfoBlock = styled.div`
//   & > h6 {
//     margin: 3px 0px;
//     color: ${({ theme }) => theme.colors.black};
//   }
// `;

const labelStyle = { fontWeight: '600', margin: '5px 0px' };

const GroupForm = ({
  initialValues,
  onCreate,
  onUpdate,
  onSubmitEnd,
  isEditable,
  changeEditState,
  submitButtonRef,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const { isSubmitting, startSubmit, endSubmit } = useIsSubmitting({
    onSubmitEnd,
  });

  const {
    // activities,
    // createdBy,
    // createdOn,
    bio: description,
    imageUrl: image,
    // memberCount: members,
    name,
    // earthTokensEarned: tokensEarned,
  } = initialValues;

  const updateGroup = (formData) => onUpdate(formData);

  const createGroup = (formData) => onCreate(formData);

  const onSubmit = async (formData) => {
    startSubmit();
    const action = initialValues ? updateGroup : createGroup;
    await action(formData);
    endSubmit();
  };

  return (
    <StyledGroupCard>
      {/* <StyledInfoWrapper>
        <StyledInfoBlock>
          <h6 style={labelStyle}>Created By</h6>
           
          TODO: add link to createdBy |missing param| 
          
           <h6>{createdBy}</h6>
        </StyledInfoBlock>
        <StyledInfoBlock>
          <h6 style={labelStyle}>Created On</h6>
          
          TODO: missing param 
          
           <h6>{createdOn}</h6>
        </StyledInfoBlock>
        <StyledInfoBlock>
          <h6 style={labelStyle}>{tokensEarned} Earth Tokens</h6>
          <h6>{activities} Total Activities</h6>
        </StyledInfoBlock>
        <StyledInfoBlock>

          TODO: add link to members 
          
          <h6>{members || '0'} Members</h6>
        </StyledInfoBlock>
      </StyledInfoWrapper> */}
      <Form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(onSubmit)}>
        <Field
          disabled={!isEditable}
          label="Group Name"
          type="text"
          formErrors={errors}
          defaultValue={name}
          inputRef={register({
            required: { value: true, message: 'Please enter group name' },
          })}
          name="name"
        />
        <TextareaField
          disabled={!isEditable}
          label="Description"
          type="text"
          formErrors={errors}
          defaultValue={description}
          inputRef={register({
            required: {
              value: true,
              message: 'Please enter group description',
            },
          })}
          name="bio"
        />
        <h6 style={labelStyle}>Group Image</h6>
        <EditableImage
          isEditable={isEditable}
          inputRef={register}
          imageUrl={image}
          name="image"
          width="100%"
        />
        {isEditable === true ? (
          <>
            <ActionWrapper>
              <CancelButton
                onClick={() => changeEditState(false)}
                type="button"
                style={{ marginRight: '35px' }}>
                Cancel
              </CancelButton>
              <PrimaryButton
                ref={submitButtonRef}
                type="submit"
                disabled={isSubmitting}>
                Save changes
              </PrimaryButton>
            </ActionWrapper>
          </>
        ) : (
          ''
        )}
      </Form>
    </StyledGroupCard>
  );
};

export default GroupForm;
