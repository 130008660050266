import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';

import { Table, TableBody, TableContainer } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { Header } from './Header';
import Row from './Row';
import {
  defaultRowsPerPage,
  rowsPerPageOptions,
} from '../../../constants/pagination';
import { Fetch } from '../../../services';
import { useQuery } from '../../../hooks';
import { errorNotification } from '../notifications/ErrorNotification';

const useTableStyles = makeStyles({
  container: {
    backgroundColor: 'white',
  },
});

// height matches height of a table row with data
const PlaceholderRow = styled.tr`
  height: 48px;
`;

const DataTable = ({
  onEdit = null,
  onDelete = null,
  onAddTokenRedemption = null,
  onResetEarthTokens = null,
  onResetConnections = null,
  columns,
  totalCount = 0,
  tableType,
  onResend = null,
  hasDetails = null,
  actionName,
  company = null,
  listFormatter = (data) => data.items,
  onRowChange,
  url: dataSource,
  refresh, // Change value of this prop in the parent to refresh the data table. Value can't be falsy.
  queryString = '',
  onTokenRedemptionAction,
}) => {
  const classes = useTableStyles();
  const [page, setPage] = useState(0);
  const [listItems, setListItems] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState();
  const [previousPageUrl, setPreviousPageUrl] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [currentPageUrl, setCurrentPageUrl] = useState('');
  const [numOfItems, setNumOfItems] = useState();

  const isPageChangeEnabled = useRef(true);

  const query = useQuery();
  const [limit, offset] = [query.get('limit'), query.get('offset')];

  const getItemList = (url) => {
    setCurrentPageUrl(url);
    Fetch.getJSON(url)
      .catch(() => {
        errorNotification('Error while getting list items');
        setListItems([]);
      })
      .then((data) => {
        if (!data) {
          setNumOfItems(0);
          return;
        }
        if (data.items.length === 0 && data.count > 0) {
          getItemList(`${data.previous}${queryString}`);
          setPage(page - 1);
          return;
        }

        setListItems(listFormatter(data, onRowChange));
        setNumOfItems(data.count);
        setNextPageUrl(`${data.next}${queryString}`);
        setPreviousPageUrl(`${data.previous}${queryString}`);
      })
      .finally(() => {
        isPageChangeEnabled.current = true;
      });
  };

  useEffect(() => {
    if (dataSource === undefined) return;
    getItemList(
      limit && offset
        ? `${dataSource}?limit=${limit}&offset=${offset}${queryString}`
        : `${dataSource}?limit=${defaultRowsPerPage}${queryString}`,
    );
    setPage(offset && limit ? offset / limit : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, queryString]);

  useEffect(() => {
    if (refresh) {
      getItemList(currentPageUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleChangePage = (_event, newPage) => {
    if (!isPageChangeEnabled.current) return;
    isPageChangeEnabled.current = false;
    getItemList(page < newPage ? nextPageUrl : previousPageUrl);

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value, 10);

    const newPageUrl = `${dataSource}?limit=${newLimit}&offset=0${queryString}`;

    getItemList(`${newPageUrl}${queryString}`);
    setRowsPerPage(newLimit);
    setPage(0);
  };

  return (
    <>
      <TableContainer className={classes.container}>
        <Table>
          <Header
            columns={columns}
            hasActions={onEdit || onDelete || onTokenRedemptionAction}
            actionName={actionName}
          />
          <TableBody>
            {listItems.map((row, index) => (
              <Row
                aria-describedby={`row-${index}`}
                key={`row-${index}`}
                rowData={row}
                columns={columns}
                onEdit={onEdit}
                onDelete={onDelete}
                onAddTokenRedemption={onAddTokenRedemption}
                onResetEarthTokens={onResetEarthTokens}
                onResetConnections={onResetConnections}
                rowType={tableType}
                onResend={onResend}
                hasDetails={hasDetails}
                company={company}
                prevPageUrlParams={currentPageUrl?.split('?')[1]}
                onTokenRedemptionAction={onTokenRedemptionAction}
              />
            ))}

            {listItems.length < rowsPerPage &&
              [...Array(rowsPerPage - listItems.length)].map((_r, index) => (
                <PlaceholderRow key={`placeholder-${index}`} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={numOfItems || totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default DataTable;
