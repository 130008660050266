import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { AddCircle } from '../../../assets/icons';
import { theme } from '../../../theme/Theme';
import Field from './Field';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  max-height: 400px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const AddFieldAction = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  svg {
    cursor: pointer;
  }
`;

const MultipleTextField = ({
  initialNumOfInputs = 2,
  errors,
  inputRef,
  name,
  placeholder,
  pattern,
}) => {
  const [numOfInputs, setNumOfInputs] = useState(initialNumOfInputs);

  const increaseNumOfInputs = () =>
    setNumOfInputs((prevCount) => prevCount + 1);

  return (
    <Container>
      {[...Array(numOfInputs)].map((_i, index) => (
        <Field
          key={`${name}-${index}`}
          placeholder={placeholder}
          type="text"
          formErrors={errors}
          inputRef={inputRef({ pattern })}
          name={`${name}[${index}]`}
        />
      ))}
      <AddFieldAction>
        <AddCircle
          color={theme.colors.darkGreen}
          onClick={increaseNumOfInputs}
        />
      </AddFieldAction>
    </Container>
  );
};

export default MultipleTextField;
