import React from 'react';
import { useForm } from 'react-hook-form';
import _get from 'lodash/get';
import styled from 'styled-components/macro';

import { PrimaryButton } from '../ui/buttons';
import { DateTimeField, Field, LogoUpload, TextareaField } from '../ui/fields';
import Form from './styles/Form';
import Title from './styles/Title';
import { useIsSubmitting } from '../../hooks/useIsSubmitting';

const ActionWrapper = styled.span`
  display: flex;
  justify-content: center;
`;

const StyledGroupCard = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  height: auto;
  width: auto;
  padding: 36px;
  border-radius: 8px;
`;

const EventForm = ({ initialValues, onUpdate, onSubmitEnd }) => {
  const { register, handleSubmit, errors, control } = useForm();
  const { isSubmitting, startSubmit, endSubmit } = useIsSubmitting({
    onSubmitEnd,
  });

  const updateEvent = (formData) => onUpdate(formData);

  const onSubmit = async (formData) => {
    startSubmit();
    await updateEvent(formData);
    endSubmit();
  };

  return (
    <StyledGroupCard>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>{initialValues ? 'Edit' : 'Create'} Event</Title>
        <Field
          label="name"
          type="text"
          formErrors={errors}
          defaultValue={_get(initialValues, 'name')}
          inputRef={register({
            required: { value: true, message: 'Please enter company name' },
          })}
          name="name"
        />
        <Field
          label="Maximum number of members"
          type="number"
          formErrors={errors}
          defaultValue={_get(initialValues, 'maxMembers')}
          inputRef={register()}
          name="maxMembers"
        />
        <DateTimeField
          label="Time"
          name="time"
          defaultValue={_get(initialValues, 'time')}
          control={control}
        />
        <Field
          label="location"
          type="text"
          formErrors={errors}
          defaultValue={_get(initialValues, 'location')}
          inputRef={register({
            required: { value: true, message: 'Please enter company name' },
          })}
          name="location"
        />
        <TextareaField
          label="description"
          type="textarea"
          formErrors={errors}
          defaultValue={_get(initialValues, 'description')}
          inputRef={register({
            required: {
              value: true,
              message: 'Please enter event description',
            },
          })}
          name="description"
        />
        <LogoUpload
          inputRef={register}
          imageUrl={_get(initialValues, 'imageUrl')}
          name="image"
        />
        <ActionWrapper>
          <PrimaryButton type="submit" disabled={isSubmitting}>
            Save
          </PrimaryButton>
        </ActionWrapper>
      </Form>
    </StyledGroupCard>
  );
};

export default EventForm;
