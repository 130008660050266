import React from 'react';
import { Card } from '@material-ui/core';
import { PrimaryButton } from '../buttons';
import SuccessIcon from '../../../assets/icons/Success';

const ConfirmModal = ({
  closeModalAndClearState,
  item,
  action,
  fullMessage,
  title,
}) => {
  const message = fullMessage || `${item} has been ${action}`;
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '20px 30px',
        height: 'auto',
        minHeight: '200px',
        width: 'auto',
        maxWidth: '400px',
        borderRadius: '8px',
        textAlign: 'center',
      }}>
      <SuccessIcon color="primary" style={{ fontSize: '48px' }} />
      {title && (
        <h2
          style={{
            fontWeight: '600, Semi Bold',
            fontSize: '28px',
            lineHeight: '33px',
            letter: '-1px',
          }}>
          {title}
        </h2>
      )}
      <h4>{message}</h4>
      <PrimaryButton onClick={closeModalAndClearState}>Done</PrimaryButton>
    </Card>
  );
};

export default ConfirmModal;
