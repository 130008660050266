export const actionTypes = {
  REQUEST_LOGIN: 'REQUEST_LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  REQUEST_LOGIN_LINK_START: 'REQUEST_LOGIN_LINK',
  REQUEST_LOGIN_LINK_ERROR: 'REQUEST_LOGIN_ERROR',
  REQUEST_LOGIN_LINK_SUCCESS: 'REQUEST_LOGIN_LINK_SUCCESS',
  IS_FIRST_LOGIN: 'IS_FIRST_LOGIN',
  LOGOUT: 'LOGOUT',
  RESET_STATUS_STATE: 'RESET_STATUS_STATE',
  NON_EXISTING_USER: 'NON_EXISTING_USER',
};
