import React from 'react';
import { useForm } from 'react-hook-form';
// import _get from 'lodash/get';
import styled from 'styled-components/macro';
import { PrimaryButton } from '../ui/buttons';
import { Field, LogoUpload, TextareaField } from '../ui/fields';
import Form from './styles/Form';
import { useIsSubmitting } from '../../hooks/useIsSubmitting';

const ActionWrapper = styled.span`
  display: flex;
  justify-content: center;
`;

const StyledGroupCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  height: auto;
  width: auto;
  padding: 36px 300px;
  border-radius: 8px;
`;

const NewGroupForm = ({ onCreateNewGroup, onSubmitEnd }) => {
  const { register, handleSubmit, errors } = useForm();
  const { isSubmitting, startSubmit, endSubmit } = useIsSubmitting({
    onSubmitEnd,
  });

  const createGroup = (formData) => onCreateNewGroup(formData);

  const onSubmit = async (formData) => {
    startSubmit();
    const action = createGroup;
    await action(formData);
    endSubmit();
  };

  return (
    <StyledGroupCard>
      <Form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit(onSubmit)}>
        <Field
          label="Group Name"
          type="text"
          formErrors={errors}
          inputRef={register({
            required: { value: true, message: 'Please enter company name' },
          })}
          name="name"
        />
        <TextareaField
          label="Description"
          type="text"
          formErrors={errors}
          inputRef={register({
            required: {
              value: true,
              message: 'Please enter group description',
            },
          })}
          name="bio"
        />

        <LogoUpload inputRef={register} name="image" />
        <ActionWrapper>
          <PrimaryButton type="submit" disabled={isSubmitting}>
            Submit
          </PrimaryButton>
        </ActionWrapper>
      </Form>
    </StyledGroupCard>
  );
};

export default NewGroupForm;
