import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';

const DateTimeField = ({
  label,
  defaultValue,
  name: inputName,
  control,
  clearable = false,
}) => {
  return (
    <>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={inputName}
        render={({ onChange, value, name, ref }) => (
          <DateTimePicker
            clearable={clearable}
            label={label}
            inputVariant="outlined"
            format="mm/dd/yyyy"
            value={value}
            inputRef={ref}
            onChange={(luxonDateTimeValue) => {
              onChange(
                luxonDateTimeValue
                  ? luxonDateTimeValue.toISO()
                  : luxonDateTimeValue,
              );
            }}
            name={name}
          />
        )}
      />
    </>
  );
};
export default DateTimeField;
