import React, { useReducer } from 'react';
import { initialState, AuthReducer } from './authReducer';

const AuthStateContext = React.createContext();

export function useAuth() {
  const context = React.useContext(AuthStateContext);

  if (context === undefined || context === null) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={{ ...user, dispatch }}>
      {children}
    </AuthStateContext.Provider>
  );
};
