import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import _get from 'lodash/get';
import { AddCircle } from '../../../assets/icons';

import { ErrorText } from '../../common';
import { theme as themeConfig } from '../../../theme/Theme';

const Container = styled.div`
  background-color: #e5e5e5;
  padding: 24px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
`;

const Image = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-color: red;
  margin-bottom: 8px;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadAction = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGreen};
  svg {
    margin-right: 4px;
  }
`;

const LogoUpload = (props) => {
  const { inputRef, formErrors, name, imageUrl } = props;
  const [imagePreview, setImagePreview] = useState(imageUrl);
  const fieldError = _get(formErrors, name);
  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <Container>
      {imagePreview && <Image src={imagePreview} />}
      <UploadAction onClick={() => fileInputRef.current.click()}>
        <AddCircle color={themeConfig.colors.darkGreen} />
        Upload Photo
      </UploadAction>
      {fieldError && <ErrorText>{fieldError.message}</ErrorText>}
      <FileInput
        ref={(e) => {
          fileInputRef.current = e;
          inputRef(e);
        }}
        onChange={handleFileChange}
        type="file"
        name={name}
      />
    </Container>
  );
};

export default LogoUpload;
