import React from 'react';
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import _get from 'lodash/get';
import { ErrorText } from '../../common';
import Label from './common/Label';

const useStyles = makeStyles(() => ({
  formControlLabel: {
    width: 'auto',
    whiteSpace: 'nowrap',
  },
  formControl: {
    width: '48%',
    height: '100%',
  },
  field: {
    marginLeft: 'auto',
    width: '70px',
  },
  radio: {
    color: 'black',
  },
  select: {
    height: '52px',
    marginTop: '8px',
    width: '100%',
    borderRadius: '6px',
  },
  MenuItem: {
    width: '100%',
  },
}));

const FormDropDown = ({
  label,
  list,
  ref,
  name,
  value,
  setValue,
  formErrors,
  isEditable,
  style = {},
  defaultValue,
  className,
  useNameAsValue,
}) => {
  const classes = useStyles();
  const fieldError = formErrors && _get(formErrors, name);
  return (
    <FormControl
      disabled={!isEditable}
      name={name}
      style={style}
      className={className || classes.formControl}>
      {label && <Label>{label}</Label>}
      <Select
        className={classes.select}
        value={`${value}`}
        ref={ref}
        defaultValue={defaultValue}
        variant="outlined"
        onChange={(e) => setValue(e.target.value)}>
        {list.map((item, index) => (
          <MenuItem
            key={`${item.name}-${index}`}
            className={classes.MenuItem}
            value={useNameAsValue ? item.name : item.value || index}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {fieldError && <ErrorText>{fieldError.message}</ErrorText>}
    </FormControl>
  );
};

export default FormDropDown;
