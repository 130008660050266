import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { PrimaryButton } from '../ui/buttons';
import { Field } from '../ui/fields';
import FormTemplate from './styles/Form';
import { Spinner } from '../ui';
import { getErrorMessageWith } from '../../utils/getErrorMessageWith';
import { serverErrors } from '../../constants/errors';
import { ErrorText } from '../common';

const getErrorMessage = getErrorMessageWith({
  [serverErrors.user.not_found]: 'The email you entered is not registered.',
  [serverErrors.user.passwordless_token_invalid]:
    'Your login token is invalid.',
  [serverErrors.http.bad_request]:
    'An unexpected error happened. Please contact your site administrator.',
});

const ActionWrapper = styled.span`
  display: flex;
  justify-content: center;
  margin: 36px 0;
  width: 100%;
`;
const Form = styled(FormTemplate)`
  justify-content: center;
  height: auto;
`;

const FormCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100%;
`;

const RetrieveButton = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.green};
  border: none;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.electricLime};
  }
`;

const FirstLoginForm = ({ onSubmit, loading, error, retrieveToggle }) => {
  const { register, handleSubmit, errors } = useForm();
  const submitHandler = (formData) => {
    onSubmit(formData);
  };
  const formContent = (
    <>
      <Field
        label="User"
        type="text"
        placeholder="Enter your username"
        style={{ width: '304px', marginBottom: '20px' }}
        formErrors={errors}
        inputRef={register({
          required: {
            value: true,
            message: 'Please enter your username',
          },
        })}
        name="userName"
      />
      <Field
        label="Password"
        type="password"
        placeholder="Enter your password"
        style={{ width: '304px' }}
        formErrors={errors}
        inputRef={register({
          required: {
            value: true,
            message: 'Please enter your password',
          },
        })}
        name="password"
      />
      <ActionWrapper>
        <PrimaryButton type="submit">Log in</PrimaryButton>
      </ActionWrapper>
    </>
  );

  return (
    <FormCard>
      <Form onSubmit={handleSubmit(submitHandler)}>
        {loading ? <Spinner /> : formContent}
        {error && <ErrorText>{getErrorMessage(error)}</ErrorText>}
      </Form>
      <RetrieveButton onClick={() => retrieveToggle()}>
        I forgot my credentials
      </RetrieveButton>
    </FormCard>
  );
};

export default FirstLoginForm;
