import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import Login from './containers/login/Login';
import { PrivateRoute } from './components/common';
import { AuthProvider } from './components/auth/AuthProvider';
import Theme from './theme/Theme';
import AppWithSuspense from './containers/app/AppWithSuspense';

function App() {
  return (
    <Theme>
      <BrowserRouter>
        <AuthProvider>
          <Switch>
            <Route
              path="/"
              exact
              component={(props) => (
                <Redirect to={`/login${props.history.location.search}`} />
              )}
            />
            <Route path="/login" component={Login} exact />
            <PrivateRoute path="/app" component={AppWithSuspense} />
            <Route component={() => <h1>Not found</h1>} />
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    </Theme>
  );
}

export default App;
