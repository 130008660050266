import { css } from 'styled-components/macro';

const LinkButtonBase = css`
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.darkGreen};
  text-decoration: underline;
  font-size: 14px;
  text-align: center;
`;

export default LinkButtonBase;
