import React from 'react';
import styled from 'styled-components';

const StyledRoundButtonGreen = styled.button`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 8px 15px;
  font-size: 16px;
  height: 43px;
  width: 43px;
  border-radius: 50%;
  border: none;
  color: white;
  background-color: ${({ theme }) => theme.colors.green};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.electricLime};
    color: ${({ theme }) => theme.colors.black};
  }
  transition: 0.1s ease;
`;
const StyledRoundButtonTransparent = styled.button`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 8px 5px;
  font-size: 16px;
  height: 43px;
  width: 43px;
  border-radius: 50%;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  background-color: transparent;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
  transition: 0.1s ease;
`;

const RoundButton = ({ icon, transparent, clickAction }) => {
  const button = transparent ? (
    <StyledRoundButtonTransparent onClick={clickAction}>
      {icon}
    </StyledRoundButtonTransparent>
  ) : (
    <StyledRoundButtonGreen onClick={clickAction}>
      {icon}
    </StyledRoundButtonGreen>
  );

  return button;
};

export default RoundButton;
