import styled from 'styled-components/macro';
import PrimaryButtonBase from './ButtonBase';

const PrimaryButton = styled.button`
  ${PrimaryButtonBase}
  background-color: ${({ theme }) => theme.colors.green};
  white-space: nowrap;
  :hover {
    transition: all 0.2s;
    color: ${({ theme }) => theme.colors.darkGreen};
    background-color: ${({ theme }) => theme.colors.electricLime};
  }
  :active {
    transition: all 0.2s;
    background-color: ${({ theme }) => theme.colors.lightGreen};
    color: ${({ theme }) => theme.colors.white};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.grey};
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: default;
  }
`;

export default PrimaryButton;
